import { useAuthState } from "react-firebase-hooks/auth";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import CreateProjectModal from "../Modals/CreateProjectModal";
import {
  setCreateProjectModal,
  setCSSForProjects,
  setCSSForSettings,
  setDeleteConfirmationStatus,
  setEditProjectModal,
  setLoaderModalStatus,
  setPlanExpiredModal,
  setUserUnverifiedModal,
} from "../Modals/ModalSlice";
import Nav from "../Nav/Nav";
import {
  auth,
  deleteProjectEntry,
  getExistingProjects,
} from "../../app/firebase";
import { useEffect, useState } from "react";
import {
  setAccountsSelectedCSS,
  setCreatedDateSelected,
  setDateSelectedCSS,
  setIsCreatedDateLatest,
  setIsName,
  setIsSortedByAccountsNumberAscending,
  setIsSortedByProjectNameAscending,
  setNameSelected,
  setProjectList,
  setProjectListBackup,
  setProjectNameSelected,
  setTotalAccountsSelected,
} from "./ProjectsSlice";
import { setCurrentSelectedProject, setUsername } from "../Home/HomeSlice";
import { useNavigate } from "react-router-dom";
import EditProjectModal from "../Modals/EditProjectModal";
import { ReactComponent as Add } from "../../assets/add.svg";
import { ReactComponent as AlphabetSort } from "../../assets/alphabetsort.svg";
import { ReactComponent as NumberSort } from "../../assets/numberSort.svg";
import { ReactComponent as CalendarSort } from "../../assets/calendar.svg";
import { ReactComponent as DeleteIcon } from "../../assets/v2/trash.svg";
import { ReactComponent as EditIcon } from "../../assets/v2/edit.svg";
import { ReactComponent as PlusIcon } from "../../assets/v2/plus.svg";
import { ReactComponent as Ellipse } from "../../assets/v2/Ellipse.svg";

import { ReactComponent as CardDelete } from "../../assets/v2/cardDelete.svg";
import { ReactComponent as CardEdit } from "../../assets/v2/cardEdit.svg";

import { ReactComponent as searchLight } from "../../assets/v2/searchLight.svg";
import { ReactComponent as searchDark } from "../../assets/v2/searchDark.svg";

import { LoaderModal } from "../Modals/LoaderModal";
import DeleteConfirmationModal from "../Modals/DeleteConfirmationModal";
import { useMemo } from "react";
import { UserUnverifiedModal } from "../Modals/UserUnverifiedModal";
import axios from "axios";
import { ADDR } from "../../config/config";
import { PlanExpiredModal } from "../Modals/planExpiredModal";

const Projects = () => {
  let mediaData;

  const [user, loading] = useAuthState(auth);
  const [isIconWhite, setIsIconWhite] = useState(false);
  const [isTotalAccountsIconWhite, setIsTotalAccountsIconWhite] =
    useState(false);
  const [isDateCreatedIconWhite, setIsDateCreatedIconWhite] = useState(false);

  let [isGridMode, setGridMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  let dispatch = useAppDispatch();
  const navigate = useNavigate();

  let projectList = useAppSelector((state) => state.projectSlice.projectList);
  let projectListBackup = useAppSelector(
    (state) => state.projectSlice.projectListBackup,
  );

  let isSortedByProjectNameAscending = useAppSelector(
    (state) => state.projectSlice.isSortedByProjectNameAscending,
  );
  let isSortedByTotalAccountsAscending = useAppSelector(
    (state) => state.projectSlice.isSortedByTotalAccountsAscending,
  );
  let isCreatedDateLatest = useAppSelector(
    (state) => state.projectSlice.isCreatedDateLatest,
  );

  let isName = useAppSelector((state) => state.projectSlice.isName);

  let projectNameSelected = useAppSelector(
    (state) => state.projectSlice.projectNameSelected,
  );
  let totalAccountsSelected = useAppSelector(
    (state) => state.projectSlice.totalAccountsSelected,
  );
  let createdDateSelected = useAppSelector(
    (state) => state.projectSlice.createdDateSelected,
  );

  let [plan, setPlan] = useState();
  let [validityDate, setValidityDate] = useState();
  let [planStatus, setPlanStatus] = useState();

  let userTypeR = useAppSelector((state) => state.homeSlice.userType.value);

  let nameSelected = useAppSelector((state) => state.projectSlice.nameSelected);

  let AccountsSelected = useAppSelector(
    (state) => state.projectSlice.AccountsSelected,
  );

  let DateSelected = useAppSelector((state) => state.projectSlice.DateSelected);

  const createNewProject = () => {
    //Invoke modal - Add a routine to create a doc in firebase to store all the usernames
    dispatch(
      setCreateProjectModal({
        status: true,
        projectName: "",
        createdDate: new Date().toDateString(),
        lastUsed: new Date().toDateString(),
        lastModified: new Date().toDateString(),
        usernames: [],
      }),
    );
  };

  const getExistingProjectsList = async () => {
    if (user) {
      dispatch(
        setLoaderModalStatus({
          status: true,
          message: "Retriving Your Projects",
        }),
      );
      let existingList = await getExistingProjects(user.uid);
      console.log(existingList);
      dispatch(setProjectList(existingList));
      dispatch(setProjectListBackup(existingList));
      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "Retriving Your Projects",
        }),
      );
    }
  };

  const getExistingProjectsFromDB = async (uid) => {
    let arr = [];
    console.log(uid);
    if (uid) {
      arr = await axios
        .post(ADDR + "/getProjectsOfAUser", { uid })
        .then((response) => {
          console.log("PROJECTOFUSER:", response);
          let parsedArray = JSON.parse(response.data[0].projects);
          return parsedArray;
        });
    }

    return arr;
  };

  const getExistingProjectListFromDB = async (adminUID) => {
    if (user) {
      if (adminUID) {
        dispatch(
          setLoaderModalStatus({
            status: true,
            message: "Retriving Your Projects",
          }),
        );
        let existingList = await getExistingProjectsFromDB(adminUID);
        console.log(existingList);
        dispatch(setProjectList(existingList));
        dispatch(setProjectListBackup(existingList));
        dispatch(
          setLoaderModalStatus({
            status: false,
            message: "Retriving Your Projects",
          }),
        );
      } else {
        console.log("IN ELSE");
        console.log(user);
        dispatch(
          setLoaderModalStatus({
            status: true,
            message: "Retriving Your Projects",
          }),
        );
        let existingList = await getExistingProjectsFromDB(user.uid);
        console.log(existingList);
        dispatch(setProjectList(existingList));
        dispatch(setProjectListBackup(existingList));
        dispatch(
          setLoaderModalStatus({
            status: false,
            message: "Retriving Your Projects",
          }),
        );
      }
    }
  };

  const openHomeWithProject = (projectName, projectList) => {
    dispatch(
      setCurrentSelectedProject({
        projectName: projectName,
        projectList: projectList,
      }),
    );
    // window.location.replace("/");
    navigate("/home", {
      state: {
        projectName: projectName,
        projectList: projectList,
      },
    });
    dispatch(
      setUsername({
        value: projectList[0],
      }),
    );
  };

  const editEntry = (item) => {
    console.log(item);
    dispatch(
      setEditProjectModal({
        status: true,
        projectName: item.projectName,
        createdDate: item.createdDate,
        lastUsed: item.lastUsed,
        lastModified: item.lastModified,
        usernames: item.usernameList,
      }),
    );
  };

  const deleteEntry = async (item) => {
    dispatch(
      setDeleteConfirmationStatus({
        status: true,
        projectName: item.projectName,
        entryUID: user.uid,
        entryItem: item,
      }),
    );

    console.log(user.uid, item);
    //  await deleteProjectEntry(user.uid,item);
  };

  const sortByProjectName = () => {
    let dataTemp = [...projectList];
    let sortedArray;
    if (isSortedByProjectNameAscending) {
      sortedArray = dataTemp.sort((a, b) =>
        b.projectName.localeCompare(a.projectName),
      );

      setIsIconWhite(true);
      setIsTotalAccountsIconWhite(false);
      setIsDateCreatedIconWhite(false);

      dispatch(
        setProjectNameSelected({
          status: true,
          css: "bg-instagram-darkgrey text-instagram-lightgrey",
        }),
      );
      dispatch(
        setTotalAccountsSelected({
          css: "",
        }),
      );
      dispatch(
        setCreatedDateSelected({
          css: "",
        }),
      );
    } else {
      sortedArray = dataTemp.sort((a, b) =>
        a.projectName.localeCompare(b.projectName),
      );
      dispatch(
        setProjectNameSelected({
          status: true,
          css: "bg-instagram-darkgrey text-instagram-lightgrey",
        }),
      );
      dispatch(
        setTotalAccountsSelected({
          css: "",
        }),
      );
      dispatch(
        setCreatedDateSelected({
          css: "",
        }),
      );
    }

    dispatch(
      setIsSortedByProjectNameAscending(!isSortedByProjectNameAscending),
    );
    dispatch(setProjectList(sortedArray));
  };

  const sortByAccountsNumber = () => {
    let dataTemp = [...projectList];
    let sortedArray;
    if (isSortedByTotalAccountsAscending) {
      sortedArray = [...dataTemp].sort(
        (a, b) => b.usernameList.length - a.usernameList.length,
      );

      handleSelection("accounts");
      setIsIconWhite(false);
      setIsTotalAccountsIconWhite(true);
      setIsDateCreatedIconWhite(false);

      dispatch(
        setTotalAccountsSelected({
          css: "bg-instagram-darkgrey text-instagram-lightgrey",
        }),
      );
      dispatch(
        setProjectNameSelected({
          status: false,
          css: "",
        }),
      );
      dispatch(
        setCreatedDateSelected({
          css: "",
        }),
      );
    } else {
      sortedArray = [...dataTemp].sort(
        (a, b) => a.usernameList.length - b.usernameList.length,
      );
      dispatch(
        setTotalAccountsSelected({
          css: "bg-instagram-darkgrey text-instagram-lightgrey",
        }),
      );

      dispatch(
        setProjectNameSelected({
          status: false,
          css: "",
        }),
      );

      dispatch(
        setCreatedDateSelected({
          css: "",
        }),
      );
    }

    dispatch(
      setIsSortedByAccountsNumberAscending(!isSortedByTotalAccountsAscending),
    );
    dispatch(setProjectList(sortedArray));
  };

  const sortByCreatedDate = () => {
    let dataTemp = [...projectList];
    console.log(dataTemp);
    let sortedLatest;
    if (isCreatedDateLatest) {
      sortedLatest = dataTemp.sort(
        (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
      );
      handleSelection("date");
      setIsIconWhite(false);
      setIsTotalAccountsIconWhite(false);
      setIsDateCreatedIconWhite(true);

      dispatch(
        setTotalAccountsSelected({
          css: "",
        }),
      );
      dispatch(
        setProjectNameSelected({
          status: false,
          css: "",
        }),
      );
      dispatch(
        setCreatedDateSelected({
          css: "bg-instagram-darkgrey text-instagram-lightgrey",
        }),
      );
    } else {
      sortedLatest = dataTemp.sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate),
      );
      dispatch(
        setCreatedDateSelected({
          css: "bg-instagram-darkgrey text-instagram-lightgrey",
        }),
      );
      dispatch(
        setProjectNameSelected({
          status: false,
          css: "",
        }),
      );
      dispatch(
        setTotalAccountsSelected({
          css: "",
        }),
      );
    }

    dispatch(setIsCreatedDateLatest(!isCreatedDateLatest));

    dispatch(setProjectList(sortedLatest));
  };

  const toggleGridMode = () => {
    setGridMode(!isGridMode);
    if (!isGridMode) {
      localStorage.setItem("gridMode", false);
    } else {
      localStorage.setItem("gridMode", true);
    }
  };

  function toggleMenu() {
    const menu = document.getElementById("floatingMenu");
    menu.classList.toggle("hidden");
  }

  const searchProjectList = (e) => {
    const input = e.target.value; // User input
    setSearchTerm(input);

    if (input.length === 0) {
      // Reset to the original list
      dispatch(setProjectList(projectListBackup));
      return;
    }

    // Filter based on project name
    const filtered = projectListBackup.filter((project) =>
      project.projectName.toLowerCase().includes(input.toLowerCase()),
    );

    // Update the list in state
    dispatch(setProjectList(filtered));

    if (isGridMode) {
      console.log("Grid Mode");
    } else {
      console.log("List Mode");
    }
  };

  const switchToSettings = async () => {
    //color and make font bold when selected
    window.location.replace("/settings");

    dispatch(setCSSForProjects("text-grey"));
    dispatch(
      setCSSForSettings("font-semibold text-darkbgone dark:text-lightbgtwo"),
    );
  };

  const getLicenseDetails = async (email, userID) => {
    console.log(email);
    console.log(userID);
    let response = await axios
      .post(ADDR + "/getLicenseDataOfUser", { email, userID })
      .then((response) => {
        return response;
      });

    console.log("RES: ", response);
    let data = response.data;
    console.log(data);
    console.log(data.length);
    if (data.length <= 0) {
      console.log("Switching to settingsl");
      // switchToSettings();
    }

    const latest = data.reduce((latestObj, currentObj) => {
      return new Date(currentObj.currentDate) > new Date(latestObj.currentDate)
        ? currentObj
        : latestObj;
    });

    console.log("Latest:", latest);

    setPlan(latest.planType);
    setValidityDate(latest.validTill);
    setPlanStatus(latest.status);
  };

  const getAdminsProjects = async (cMail, cUID) => {
    //Case: Another separate user adding a user aggain as team memeber (Shouldnt work and should give user feedback about the same)

    //Get admins UID first based on current users email address;
    await axios
      .post(ADDR + "/getAdminUIDByUserEmail", { email: cMail })
      .then((response) => {
        console.log("RES2:", response);
        let adminUid = response.data[0].AddedByUserID;
        console.log(adminUid);
        getExistingProjectListFromDB(adminUid);
      });
  };

  const handleSelection = (type) => {
    const defaultCss = "";
    const selectedCss = "font-bold text-darkbgone dark:text-lightbgtwo";

    // setIsMediaIconWhite(type === "mediatype");
    // setIsLikeIconWhite(type === "likes");
    // setIsCommentIconWhite(type === "comments");
    // setIsCalendarIconWhite(type === "date");
    // setIsEngagementIconWhite(type === "engagement");
    // setIsReachIconWhite(type === "reach");
    // setIsUsernameIconWhite(type === "username");
    dispatch(
      setNameSelected({
        css: type === "name" ? selectedCss : defaultCss,
      }),
    );
    dispatch(
      setAccountsSelectedCSS({
        css: type === "accounts" ? selectedCss : defaultCss,
      }),
    );
    dispatch(
      setDateSelectedCSS({
        css: type === "date" ? selectedCss : defaultCss,
      }),
    );
  };

  let sortByUsernameAlphabetically = () => {
    let dataTemp = [...projectList];
    let sortedArray = dataTemp.sort((a, b) => {
      // Compare usernames alphabetically
      if (a.projectName.toLowerCase() < b.projectName.toLowerCase()) {
        return isName ? -1 : 1; // Ascending if isReach is true, else Descending
      }
      if (a.projectName.toLowerCase() > b.projectName.toLowerCase()) {
        return isName ? 1 : -1; // Ascending if isReach is true, else Descending
      }
      return 0; // If they are equal
    });
    handleSelection("name");
    dispatch(setIsName(!isName));
    dispatch(setProjectList([...sortedArray]));
  };

  useEffect(() => {
    console.log("vDate:", validityDate);

    // Parse the validityDate and the current date
    const validDate = new Date(validityDate);
    const today = new Date();

    // Compare dates
    if (validDate < today) {
      console.log("plan expired");
      dispatch(
        setPlanExpiredModal({
          status: true,
          message: "Plan Expired - Please Renew",
        }),
      );
    }
  }, [validityDate]);

  useEffect(() => {
    if (planStatus === "pending") {
      dispatch(
        setUserUnverifiedModal({
          status: true,
          message: "Approval Pending",
        }),
      );
    } else if (planStatus === "approved") {
      dispatch(
        setUserUnverifiedModal({
          status: false,
          message: "",
        }),
      );
    } else if (planStatus === "declined") {
      dispatch(
        setUserUnverifiedModal({
          status: true,
          message: "Access Denied",
        }),
      );
    }
  }, [planStatus]);

  useEffect(() => {
    console.log(projectList);
    if (loading) {
      // show loader (you can manage this by setting a state or directly within JSX)
      return; // Early return to prevent further execution
    }

    if (user) {
      if (projectList.length === 0) {
        getExistingProjectListFromDB();
        getLicenseDetails(user.email, user.uid);
        // //Checking if user is a team memeber and calling admins projects
        // if(userTypeR==="User"){
        //   console.log("Calling function fior USER");
        //   getAdminsProjects(user.email, user.uid)

        // }
      } else {
        //Do nothing
      }
    } else {
      window.location.replace("/login");
    }
  }, [user, loading]);

  useEffect(() => {
    if (user) {
      console.log("Test", userTypeR);
      if (userTypeR === "User") {
        console.log("I AM A USER");
        getAdminsProjects(user.email);
      }
    }
  }, [userTypeR]);

  useEffect(() => {
    const gridMode = localStorage.getItem("gridMode");
    console.log(gridMode);
    if (gridMode) {
      setGridMode(true);
    } else {
      setGridMode(false);
    }

    dispatch(
      setCSSForProjects("font-semibold text-darkbgone dark:text-lightbgtwo"),
    );
    dispatch(setCSSForSettings("text-grey"));
  }, []);

  //www.youtube.com/watch?v=MbQv8zoNEfY&t=129s

  https: return (
    <>
      {/* <Nav /> */}
      <div className="flex justify-center items-center mt-24 flex-col">
        <div className="flex justify-end"></div>

        <div className="my-4 mx-10 flex flex-col justify-center w-[1000px]">
          <div className="flex justify-between">
            <h1 className="text-3xl font-bold my-5">Projects</h1>

            <div class="h-10 my-5 px-5 flex items-center border-b border-grey">
              <svg
                class="w-6 h-6 mr-2 font-bold text-darkbgone dark:text-lightbgtwo "
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-4.35-4.35M16 10a6 6 0 11-12 0 6 6 0 0112 0z"
                />
              </svg>

              <input
                type="text"
                placeholder="Search for project"
                value={searchTerm}
                onChange={searchProjectList}
                class="bg-transparent outline-none focus-none border-none placeholder-gray-500 w-full focus:outline-none"
              />
            </div>

            <div class=" flex items-center space-x-4">
              <button
                onClick={createNewProject}
                class="bg-lightbgtwo dark:bg-darkbgthree rounded-full h-8 w-8 flex items-center justify-center hover:invert"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </button>

              <div class="relative inline-block">
                <button
                  onClick={toggleMenu}
                  class="bg-lightbgthree dark:bg-darkbgthree rounded-[12px] px-4 py-2 flex items-center space-x-2 shadow-md hover:bg-gray-200 dark:hover:bg-darkbgfour focus:outline-none"
                >
                  <span>Filter by</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                <div
                  id="floatingMenu"
                  class="hidden absolute text-left left-0 mt-2 w-[114px] bg-lightbgthree dark:bg-darkbgthree rounded-[12px] shadow-lg z-50"
                  onClick={sortByProjectName}
                >
                  <a
                    href="#"
                    class="block mx-1 my-1 px-4 py-2 text-darkbgone dark:text-lightbgone hover:bg-lightbgtwo dark:hover:bg-darkbgfour rounded-[12px] hover:cursor-pointer"
                  >
                    Name
                  </a>
                  <a
                    href="#"
                    class="block mx-1 my-1 px-4 py-2 text-darkbgone dark:text-lightbgone hover:bg-lightbgtwo dark:hover:bg-darkbgfour rounded-[12px] hover:cursor-pointer"
                    onClick={sortByAccountsNumber}
                  >
                    Accounts
                  </a>
                  <a
                    href="#"
                    class="block mx-1 my-1 px-4 py-2 text-darkbgone dark:text-lightbgone hover:bg-lightbgtwo dark:hover:bg-darkbgfour rounded-[12px] hover:cursor-pointer"
                    onClick={sortByCreatedDate}
                  >
                    Date
                  </a>
                </div>
              </div>

              {/* <button onClick={toggleGridMode} 
              class="flex bg-lightbgtwo dark:bg-darkbgthree rounded-[12px] p-1">
                <div class=" px-4 py-2 bg-lightbgthree dark:bg-darkbgfour rounded-[12px]">
                  Grid
                </div>
                <div class=" px-4 py-2 bg-lightbgtwo dark:bg-darkbgthree rounded-[12px]">
                  List
                </div>
              </button> */}

              <div className="relative flex items-center bg-lightbgtwo dark:bg-darkbgthree rounded-[12px] p-1">
                {/* Sliding Highlight */}
                <div
                  className={`absolute w-[47%] h-10 bg-lightbgthree dark:bg-darkbgfour rounded-[12px] transition-transform duration-300 ease-in-out ${
                    isGridMode ? "translate-x-0" : "translate-x-full"
                  }`}
                ></div>

                {/* Grid Option */}
                <button
                  onClick={() => {
                    setGridMode(true);
                    localStorage.setItem("gridMode", true);
                  }}
                  className={`relative flex-1 text-center z-10 px-4 py-2 font-medium ${
                    isGridMode ? "text-black dark:text-white" : "text-gray-500"
                  }`}
                >
                  Grid
                </button>

                {/* List Option */}
                <button
                  onClick={() => {
                    setGridMode(false);
                    localStorage.setItem("gridMode", false);
                  }}
                  className={`relative flex-1 text-center z-10 -pl-1 px-4 py-2 font-medium ${
                    !isGridMode ? "text-black dark:text-white" : "text-gray-500"
                  }`}
                >
                  List
                </button>
              </div>
            </div>
          </div>

          {isGridMode && (
            <>
              {/* Grid Mode */}
              <div
                onClick={createNewProject}
                class="flex grid grid-cols-4 gap-5 gap-x-14 w-full max-w-[1000px]"
              >
                <div class="flex flex-col justify-center w-[210px] h-[240px] rounded-lg bg-gradient-to-b from-darkbggradientfrom to-darkbggradientto shadow-lg hover:cursor-pointer transform transition-transform duration-300 hover:scale-110">
                  <div className="flex flex-col items-center flex-1 justify-center">
                    <PlusIcon className="w-8 h-8" />
                  </div>

                  <div className="flex justify-center py-4">
                    <p class="font-bold text-xl text-lightbgtwo">New Project</p>
                  </div>
                </div>
                {projectList &&
                  projectList.length > 0 &&
                  projectList.map((item, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        openHomeWithProject(item.projectName, item.usernameList)
                      }
                      class="flex flex-col w-[210px] h-[240px] rounded-lg bg-lightbgtwo dark:bg-darkbgtwo hover:bg-gradient-to-b hover:from-darkbggradientfrom hover:to-darkbggradientto shadow-lg hover:text-lightbgtwo hover:cursor-pointer  transform transition-transform duration-300 hover:scale-110"
                    >
                      <div className="flex justify-end flex-col gap-40">
                        <div className="flex justify-end space-x-2 mx-2 mt-2">
                          <button
                            className="hover:scale-110"
                            onClick={(e) => {
                              e.stopPropagation();
                              editEntry(item);
                            }}
                          >
                            <EditIcon className="w-6 h-6 rounded-full text-darkbgone dark:text-lightbgone" />
                          </button>
                          <button
                            className="hover:scale-110"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteEntry(item);
                            }}
                          >
                            <DeleteIcon className="w-6 h-6 rounded-full text-darkbgone dark:text-lightbgone" />
                          </button>
                        </div>

                        <div className="">
                          <p class="font-bold text-xl text-left px-2 -mt-2">
                            {" "}
                            {item.projectName}
                          </p>
                          <p class="font-semibold text-sm text-grey px-2 text-left flex gap-1">
                            {" "}
                            {item.usernameList.length} Accounts
                            <Ellipse className="my-2" />{" "}
                            {new Date(item.createdDate).getFullYear() +
                              "-" +
                              (new Date(item.createdDate).getMonth() + 1) +
                              "-" +
                              new Date(item.createdDate).getDate()}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}

          {!isGridMode && (
            <>
              {/* List Mode */}
              {/* Top level container for posts/ */}
              <div class="bg-lightbgtwo dark:bg-darkbgtwo flex flex-col justify-between w-full max-w-[1000px] rounded-[20px] px-5 pb-5 border border-grey">
                {/* Header */}

                <div className="flex w-full justify-between">
                  <div>
                    <h1 className="text-3xl font-bold my-5">Project List</h1>
                  </div>
                  <div>
                    {" "}
                    {projectList !== undefined && (
                      <p className="my-7 font-semibold">
                        {projectList.length} Projects
                      </p>
                    )}
                  </div>
                </div>

                {/* Table Header  */}

                {/* Table Header */}
                <div className="overflow-x-auto overflow-y-auto">
                  <table className="min-w-full table-auto">
                    <thead>
                      <tr className="text-grey dark:text-grey bg-transparent border-b border-grey">
                        <th
                          onClick={sortByUsernameAlphabetically}
                          className={"px-4 py-2 text-left " + nameSelected.css}
                        >
                          Name
                        </th>
                        <th
                          onClick={sortByAccountsNumber}
                          className={
                            "px-4 py-2 text-left " + AccountsSelected.css
                          }
                        >
                          Accounts
                        </th>
                        {/* <th className="px-4 py-2 text-left">Last Used</th> */}
                        <th
                          className={"px-4 py-2 text-left " + DateSelected.css}
                          onClick={sortByCreatedDate}
                        >
                          Date Added
                        </th>
                        <th className="px-4 py-2 text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {projectList !== undefined && projectList.length > 0 ? (
                        projectList.map((item) => (
                          <tr
                            key={item.id}
                            className="hover:cursor-pointer dark:hover:bg-lightbgone dark:hover:text-darkbgone hover:bg-darkbgone hover:text-lightbgone text-darkbgone font-semibold dark:text-lightbgone border-b border-grey"
                            onClick={() =>
                              openHomeWithProject(
                                item.projectName,
                                item.usernameList,
                              )
                            }
                          >
                            <td className="px-4 py-2 text-left">
                              {item.projectName}
                            </td>
                            <td className="px-4 py-2 text-left">
                              {item.usernameList.length}
                            </td>
                            {/* <td className="px-4 py-2 text-left">
                              {new Date(item.createdDate).getFullYear() +
                                "-" +
                                (new Date(item.createdDate).getMonth() + 1) +
                                "-" +
                                new Date(item.createdDate).getDate()}
                            </td> */}
                            <td className="px-4 py-2 text-left">
                              {new Date(item.createdDate).getFullYear() +
                                "-" +
                                (new Date(item.createdDate).getMonth() + 1) +
                                "-" +
                                new Date(item.createdDate).getDate()}
                            </td>
                            <td className="px-4 py-2">
                              <div className="flex justify-end space-x-2">
                                <button
                                  className="hover:scale-110"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    editEntry(item);
                                  }}
                                >
                                  <EditIcon className="w-6 h-6 rounded-full text-darkbgone dark:text-lightbgone" />
                                </button>
                                <button
                                  className="hover:scale-110"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deleteEntry(item);
                                  }}
                                >
                                  <DeleteIcon className="w-6 h-6 rounded-full text-darkbgone dark:text-lightbgone" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="5"
                            className="text-center my-2 border-b border-grey font-medium px-5 py-5"
                          >
                            No Projects Available At The Moment
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <CreateProjectModal />
      <EditProjectModal /> <LoaderModal />
      <DeleteConfirmationModal />
      <UserUnverifiedModal />
      <PlanExpiredModal />
    </>
  );
};

export default Projects;
