import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import {
  setCreateProjectModal,
  setDeleteConfirmationStatus,
  setEditProjectModal,
  setErrorModalStatus,
  setLoaderModalStatus,
} from "./ModalSlice";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import LoadingIcons from "react-loading-icons";
import { useAuthState } from "react-firebase-hooks/auth";
import { addUserProject, auth, updateProject } from "../../app/firebase";
import { setCurrentSelectedProject } from "../Home/HomeSlice";
import { useNavigate } from "react-router-dom";
import { ErrorModal } from "./ErrorModal";
import { LoaderModal } from "./LoaderModal";
import { setProjectList } from "../Projects/ProjectsSlice";
import axios from "axios";
import { ADDR } from "../../config/config";

const EditProjectModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let isOpen = useAppSelector(
    (state) => state.modalSlice.editProjectModal.status,
  );
  let projectName = useAppSelector(
    (state) => state.modalSlice.editProjectModal.projectName,
  );
  let createProjectModalObject = useAppSelector(
    (state) => state.modalSlice.editProjectModal,
  );

  let usernameList = useAppSelector(
    (state) => state.modalSlice.editProjectModal.usernames,
  );

  let createdDate = useAppSelector(
    (state) => state.modalSlice.editProjectModal.createdDate,
  );
  let lastModified = useAppSelector(
    (state) => state.modalSlice.editProjectModal.lastModified,
  );
  let lastUsed = useAppSelector(
    (state) => state.modalSlice.editProjectModal.lastUsed,
  );

  let projectList = useAppSelector((state) => state.projectSlice.projectList);

  const uid = useAppSelector(
    (state) => state.modalSlice.deleteConfirmationModal.entryUID,
  );

  const [newUsername, setNewUsername] = useState(""); // State for input field
  const [user, loading] = useAuthState(auth);
  const [usernameListBackup, setUsernameBackupList] = useState([]);

  const [removedUsernames, setRemovedUsernames] = useState([]);

  const closeModal = () => {
    dispatch(
      setEditProjectModal({
        status: false,
        projectName: "",
        createdDate: new Date().toDateString(),
        lastUsed: new Date().toDateString(),
        lastModified: new Date().toDateString(),
        usernames: [],
      }),
    );
  };

  const projectNameHandler = (e) => {
    let temp = { ...createProjectModalObject };
    temp.projectName = e.target.value;
    dispatch(setEditProjectModal(temp));
  };

  const addUsernameHandler = () => {
    if (newUsername.trim() !== "") {
      let temp = { ...createProjectModalObject };
      temp.usernames = [...temp.usernames, newUsername];
      dispatch(setEditProjectModal(temp));
      setNewUsername(""); // Clear the input after adding
    }
  };

  const updateProjectFirebase = async () => {
    let uid = user.uid;
    let projectNameTemp = projectName;
    let userList = usernameList;
    let cDate = createdDate;
    let lModified = lastModified;
    let lUsed = lastUsed;
    let res = await updateProject(projectNameTemp, userList);
    console.log(res);
  };

  const updateProjectInDB = async () => {
    dispatch(
      setLoaderModalStatus({
        status: true,
        message: "Updating " + projectName,
      }),
    );

    try {
      console.log("uid:", uid);
      console.log("projectName:", projectName);

      let uidUser = user.uid;
      // Prepare the updated project data to send to the backend
      const updatedProjectData = [...usernameList];

      console.log("UpdatedUser:", updatedProjectData);
      console.log("Removed Usernames :", removedUsernames);

      // Send a request to the backend to update the project
      const response = await axios
        .post(ADDR + "/editProjectFromProjectList", {
          uidUser,
          projectName,
          updatedProjectData,
          removedUsernames,
        })
        .then((response) => {
          // Reload here is correct
          window.location.reload();
        });

      console.log("Backend Response:", response);

      dispatch(
        setEditProjectModal({
          status: false,
          projectName: "",
          createdDate: new Date().toDateString(),
          lastUsed: new Date().toDateString(),
          lastModified: new Date().toDateString(),
          usernames: [],
        }),
      );

      console.log("UPList:", projectList);

      // dispatch(
      //   setCurrentSelectedProject({
      //     projectName: projectName,
      //     projectList: projectList,
      //   }),
      // );

      // dispatch(
      //   setCurrentSelectedProject({
      //     projectName: projectName,
      //     projectList: projectList,
      //   }),
      // );
      // window.location.replace("/");
      // navigate("/home", {
      //   state: {
      //     projectName: projectName,
      //     projectList: projectList,
      //   },
      // });

      //PROFILES NOT ADDING IN REALTIME - WILL DO LATER

      // window.location.reload();

      dispatch(
        setDeleteConfirmationStatus({
          status: false,
          message: "",
        }),
      );

      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "",
        }),
      );
    } catch (error) {
      console.error("Error updating project:", error);

      // Show an error modal
      dispatch(
        setErrorModalStatus({
          status: true,
          message: "Error Updating",
        }),
      );

      // Close the loader modal
      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "",
        }),
      );
    }
  };

  // const updateProjectInDB = async () =>{
  //   dispatch(
  //     setLoaderModalStatus({
  //       status: true,
  //       message: "Updating " + projectName,
  //     }),
  //   );

  //   try {
  //     console.log("uid:", uid);
  //     console.log(projectName);

  //     let projectName = projectName;

  //     //pass this to backend - filter out and update the list in backend and send respond.
  //     await axios
  //       .post(ADDR + "/deleteProjectFromProjectList", { uid, projectName })
  //       .then((response) => {
  //         console.log(response);

  //         dispatch(
  //           setDeleteConfirmationStatus({
  //             status: false,
  //             message: "",
  //           }),
  //         );

  //         // Assuming `arrayName` is your current project list array from Redux state
  //         const updatedProjectList = projectList.filter(
  //           (project) => project.projectName !== projectName,
  //         );

  //         // Dispatch the updated list
  //         dispatch(setProjectList(updatedProjectList));

  //         dispatch(
  //           setLoaderModalStatus({
  //             status: false,
  //             message: "Updating " + projectName,
  //           }),
  //         );
  //       });
  //   } catch (e) {
  //     dispatch(
  //       setErrorModalStatus({
  //         status: true,
  //         message: "Error Updating",
  //       }),
  //     );

  //     dispatch(
  //       setLoaderModalStatus({
  //         status: false,
  //         message: "Updating " + projectName,
  //       }),
  //     );
  //   }

  // }

  const removeUsernameHandler = (username) => {
    let arrayCopy = [...removedUsernames];

    const updatedModal = {
      ...createProjectModalObject, // Already fetched via `useAppSelector`
      usernames: usernameList.filter((user) => user !== username),
    };

    arrayCopy.push(username);

    setRemovedUsernames([...arrayCopy]);

    dispatch(setEditProjectModal(updatedModal));
  };

  const renderUsernameList = () =>
    usernameList.map((username, index) => (
      <div
        key={index}
        className="flex mx-1 my-1 bg-lightbgtwo dark:bg-darkbgfour rounded-[12px] w-fit"
      >
        <p className="my-1 ml-2 dark:text-lightbgtwo">
          {"@"}
          {username}
        </p>
        <button
          onClick={() => removeUsernameHandler(username)}
          className="cursor-pointer rounded-full w-[16px] h-[16px] my-2 mx-2 flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-[16px] h-[16px] dark:text-white text-black"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    ));

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-lightbgone dark:bg-darkbgtwo opacity-50" />
          </Transition.Child>

          <div className="fixed  inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full  border border-1 border-darkbgfour max-w-md transform overflow-hidden rounded-2xl bg-instagram-dark backdrop-blur-xl opacity-100 p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl text-left font-semibold text-darkbgone dark:text-lightbgone py-2"
                    >
                      Edit Project
                    </Dialog.Title>

                    <button
                      onClick={closeModal}
                      class="cursor-pointer rounded-full bg-grey dark:bg-lightbgtwo w-10 h-10 my-2 mx-4 flex justify-center items-center "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="w-6 h-6 dark:text-black text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="mt-2 flex flex-col justify-center">
                    <label className="ml-4 text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                      Project Name
                    </label>

                    <input
                      placeholder="Enter Project Name"
                      value={projectName}
                      onChange={projectNameHandler}
                      className="w-full rounded-[12px] text-left pl-4 py-2 my-2 font-medium"
                    />

                    <div className="flex flex-col w-full">
                      <label className="ml-4 text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                        Instagram Username
                      </label>
                      <div className="flex">
                        <input
                          placeholder="unbubble"
                          value={newUsername}
                          onChange={(e) => setNewUsername(e.target.value)}
                          className="w-5/6 rounded-[12px] text-left pl-4 py-2 my-2 font-medium"
                        />

                        <button
                          onClick={addUsernameHandler}
                          class="cursor-pointer rounded-full bg-lightbgtwo dark:bg-darkbgthree w-10 h-10 my-2 mx-4 flex justify-center items-center "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="w-6 h-6 dark:text-white text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 4v16m8-8H4"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <legend class="border-t border-grey w-full mt-2"></legend>

                    <div className="mt-4 w-full h-40 overflow-y-auto rounded-lg">
                      {usernameList.length ? (
                        <div className="grid grid-cols-2">
                          {renderUsernameList()}
                        </div>
                      ) : (
                        <p className="text-grey ml-2">
                          No usernames added yet.
                        </p>
                      )}
                    </div>

                    <div className="mt-4">
                      <button
                        onClick={updateProjectInDB}
                        className="w-full cursor-pointer my-2 text-lightbgtwo bg-primary font-semibold rounded-[20px] text-lg px-5 py-4 text-center mr-3 md:mr-0 flex justify-center items-center"
                      >
                        Update Project
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <LoaderModal />
      <ErrorModal />
    </>
  );
};

export default EditProjectModal;
