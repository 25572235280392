//Offline Config
// export const PROTOCOL = "http";
// export const IP = "192.168.1.189";

// // export const IP = "127.0.0.1";
// export const PORT = "3010";
// export const ADDR = PROTOCOL + "://" + IP + ":" + PORT;

//Online Config

export const PROTOCOL = "https";
export const IP = "metainsights-production-app-76d4bfe2ad52.herokuapp.com";
export const ADDR = PROTOCOL + "://" + IP;

