import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { Link } from "react-scroll";

import navLogo from "../../assets/navlogo.png";
import dp from "../../assets/v2/profile.png";
import DarkModeToggle from "./DarkModeToggle/DarkModeToggle";

import { ReactComponent as ArrowDown } from "../../assets/v2/downarrow.svg";
import { auth, getDP, getName, logout } from "../../app/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setNameOfUser, setUserTypeR } from "../Home/HomeSlice";
import { setCSSForProjects, setCSSForSettings } from "../Modals/ModalSlice";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ADDR } from "../../config/config";

const NewNav = () => {
  let dispatch = useAppDispatch();
  const location = useLocation();

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [userDP, setUserDP] = useState(null);
  const [Name, setName] = useState(null);
  const [userType, setUserType] = useState(null);
  //   const [cssForProjects, setCSSForProjects] = useState("text-grey");
  //   const [cssForSettings, setCSSForSettings] = useState("text-grey");
  const [isLoading, setIsLoading] = useState(false);

  let userTypeR = useAppSelector((state) => state.homeSlice.userType.value);

  let cssForProjects = useAppSelector(
    (state) => state.modalSlice.cssForProjects,
  );
  let cssForSettings = useAppSelector(
    (state) => state.modalSlice.cssForSettings,
  );
  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const getUserDP = async () => {
    let localDPExists = localStorage.getItem("DPOFUSER");
    localDPExists = localDPExists ? JSON.parse(localDPExists) : undefined;

    console.log("LOCALDP:", localDPExists);

    if (user && localDPExists === undefined) {
      // Fetch the DP from the server
      let dp = await getDP(user.uid);
      setUserDP(dp);

      // Save in local storage too
      localStorage.setItem(
        "DPOFUSER",
        JSON.stringify({ uid: user.uid, dp: dp }),
      );
    } else if (user && localDPExists) {
      console.log("SETTING FROM LOCAL");

      if (user.uid === localDPExists.uid) {
        setUserDP(localDPExists.dp);
      } else {
        // Fetch the DP from the server
        let dp = await getDP(user.uid);
        setUserDP(dp);

        // Save in local storage too
        localStorage.setItem(
          "DPOFUSER",
          JSON.stringify({ uid: user.uid, dp: dp }),
        );
      }
    }
  };

  // const getUserDP = async () => {
  //   let localDPExists = localStorage.getItem("DPOFUSER");

  //   console.log("LOCALDP: ",localDPExists);

  //   if (user && localDPExists !== undefined) {
  //     let dp = await getDP(user.uid);
  //     setUserDP(dp);

  //     //Save in local storage too

  //     localStorage.setItem("DPOFUSER", { uid: user.uid, dp: dp });
  //   } else if (user && localDPExists) {
  //     console.log("SETTING FROM LOCAL");
  //     if (user.uid === localDPExists.uid) {
  //       console.log("SETTING FROM LOCAL");

  //       setUserDP(localDPExists.dp);
  //     } else {
  //       let dp = await getDP(user.uid);
  //       setUserDP(dp);

  //       //Save in local storage too

  //       localStorage.setItem("DPOFUSER", { uid: user.uid, dp: dp });
  //     }
  //   }
  // };

  //   const getNameOfUser = async () => {
  //     let fname;
  //     if (user && Name === null) {
  //       let name = await getName(user.uid);
  //       console.log("DP:", name);
  //       if (name !== undefined) {
  //         fname = name.split(" ")[0].trim();
  //       }
  //       setName(fname);
  //       dispatch(
  //         setNameOfUser({
  //           value: name,
  //         }),
  //       );
  //     }
  //   };

  // const getDPOfUserFromDB = async () => {
  //   let dp;
  //   if (user && userDP === null) {
  //     await axios
  //       .post(ADDR + "/getDPOfUser", { email: user.email, userID: user.uid })
  //       .then((response) => {
  //         console.log("GETDP:", response);
  //         dp = response.data[0].displayImage;
  //         setUserDP(dp);
  //       });
  //   }
  // };

  const getNameOfUserFromDB = async () => {
    let fname;

    let name;
    let userType;
    if (user) {
      await axios
        .post(ADDR + "/getNameAndUserTypeOfUser", {
          email: user.email,
          userID: user.uid,
        })
        .then((response) => {
          name = response.data[0].name;
          userType = response.data[0].userType;
          console.log(name);

          if (name !== undefined) {
            fname = name.split(" ")[0].trim();
          }

          if (userType !== undefined) {
            userType = userType.charAt(0).toUpperCase() + userType.slice(1);
          }

          setName(fname);
          setUserType(userType);
          dispatch(
            setNameOfUser({
              value: name,
            }),
          );

          dispatch(
            setUserTypeR({
              value: userType,
            }),
          );

          console.log(userType);
        });
    }
  };

  const switchToProjects = async () => {
    window.location.replace("/projects");

    dispatch(
      setCSSForProjects("font-semibold text-darkbgone dark:text-lightbgtwo"),
    );
    dispatch(setCSSForSettings("text-grey"));
  };

  const switchToSettings = async () => {
    //color and make font bold when selected
    window.location.replace("/settings");

    dispatch(setCSSForProjects("text-grey"));
    dispatch(
      setCSSForSettings("font-semibold text-darkbgone dark:text-lightbgtwo"),
    );
  };

  useEffect(() => {
    console.log("HOOK:", userType);
    dispatch(
      setUserTypeR({
        value: userType,
      }),
    );
  }, [userType]);

  useEffect(() => {
    console.log("USER: ", user);

    if (loading) {
      return;
    }

    if (user && location.pathname !== "/register") {
      if (userDP === null) {
        // getNameOfUser();
        // getUserDP();
      }

      getUserDP();

      getNameOfUserFromDB();
    }
  }, [user]);

  useEffect(() => {
    console.log("USERr:", userTypeR);
  }, [userTypeR]);

  return (
    <nav className="fixed left-0 top-0 right-0 navbar-top z-50 px-2 sm:px-2 py-5 rounded">
      <div className="container flex flex-wrap justify-between mx-auto">
        <a href="/projects" className="flex items-center">
          <h1
            className="mr-2 sm:h-10 text-3xl font-bold pt-2"
            alt="unbubble GmbH Logo"
          >
            unbubble
          </h1>
        </a>

        {location.pathname !== "/login" &&
          location.pathname !== "/register" &&
          location.pathname !== "/privacy-policy" &&
          location.pathname !== "/terms-of-service" && (
            <div className="flex justify-center font-semibold gap-4 items-center">
              <p
                onClick={switchToProjects}
                className={
                  " hover:text-darkbgone dark:hover:text-lightbgtwo cursor-pointer " +
                  cssForProjects
                }
              >
                Projects
              </p>
              <p
                onClick={switchToSettings}
                className={
                  " hover:text-darkbgone dark:hover:text-lightbgtwo cursor-pointer " +
                  cssForSettings
                }
              >
                Settings
              </p>
            </div>
          )}

        <div className="flex gap-2 justify-center">
          <div className="pt-3">
            <DarkModeToggle />
          </div>

          {!isLoading && !user && (
            <div>
              <div
                onClick={toggleUserMenu}
                className={`flex gap-3 px-1 py-1 rounded-full hover:cursor-pointer ${
                  isUserMenuOpen ? "bg-grey" : "hover:bg-grey"
                }`}
              >
                <img
                  src={dp}
                  height={45}
                  width={45}
                  className="select-none rounded-full"
                />

                <div className="pt-1 -ml-[5px]">
                  <p className="text-md font-bold text-left">
                    {Name || "Robot"}
                  </p>
                  <p className="text-left text-sm text-grey -mt-1">
                    {userType || "Admin"}
                  </p>
                </div>

                <div className="flex flex-col justify-center">
                  <ArrowDown
                    className="dark:text-lightbgtwo text-darkbgone"
                    width={15}
                    height={15}
                  />
                </div>
              </div>

              <div className="relative flex flex-col font-semibold">
                <div
                  id="floatingUserMenu"
                  className={`${
                    isUserMenuOpen ? "" : "hidden"
                  } mt-2 absolute text-left font-semibold w-[130px] bg-lightbgthree dark:bg-darkbgthree rounded-[12px] shadow-lg z-50`}
                >
                  <a
                    className="block select-none mx-1 my-1 px-4 py-2 text-darkbgone dark:text-lightbgone hover:bg-lightbgtwo dark:hover:bg-darkbgfour rounded-[12px] hover:cursor-pointer"
                    data-value="logout"
                    onClick={logout}
                  >
                    Logout
                  </a>
                </div>
              </div>
            </div>
          )}

          {!isLoading && user && (
            <div>
              <div
                onClick={toggleUserMenu}
                className={`flex gap-3 px-1 py-1 rounded-full hover:cursor-pointer ${
                  isUserMenuOpen ? "bg-grey" : "hover:bg-grey"
                }`}
              >
                <img
                  src={userDP || dp}
                  height={45}
                  width={45}
                  className="select-none rounded-full"
                />

                <div className="pt-1 -ml-[5px]">
                  <p className="text-md font-bold text-left">
                    {Name || "User"}
                  </p>
                  <p className="text-left text-sm text-grey -mt-1">
                    {userType || "User"}
                  </p>
                </div>

                <div className="flex flex-col justify-center">
                  <ArrowDown
                    className="dark:text-lightbgtwo text-darkbgone"
                    width={15}
                    height={15}
                  />
                </div>
              </div>

              <div className="relative flex flex-col font-semibold">
                <div
                  id="floatingUserMenu"
                  className={`${
                    isUserMenuOpen ? "" : "hidden"
                  } mt-2 absolute text-left font-semibold w-[130px] bg-lightbgthree dark:bg-darkbgthree rounded-[12px] shadow-lg z-50`}
                >
                  <a
                    className="block select-none mx-1 my-1 px-4 py-2 text-darkbgone dark:text-lightbgone hover:bg-lightbgtwo dark:hover:bg-darkbgfour rounded-[12px] hover:cursor-pointer"
                    data-value="logout"
                    onClick={logout}
                  >
                    Logout
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NewNav;
