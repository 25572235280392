import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setDeleteConfirmationStatus,
  setErrorModalStatus,
  setLoaderModalStatus,
  setProfileInfoModalStatus,
} from "./ModalSlice";
import { useAppSelector } from "../../app/hooks";
import { deleteProjectEntry } from "../../app/firebase";
import axios from "axios";
import { ADDR } from "../../config/config";
import { setProjectList } from "../Projects/ProjectsSlice";

export const DeleteConfirmationModal = () => {
  const dispatch = useDispatch();

  let projectList = useAppSelector((state) => state.projectSlice.projectList);

  const isOpen = useAppSelector(
    (state) => state.modalSlice.deleteConfirmationModal.status,
  );
  const projectName = useAppSelector(
    (state) => state.modalSlice.deleteConfirmationModal.projectName,
  );

  const uid = useAppSelector(
    (state) => state.modalSlice.deleteConfirmationModal.entryUID,
  );

  const item = useAppSelector(
    (state) => state.modalSlice.deleteConfirmationModal.entryItem,
  );

  const closeModal = () => {
    const tempProfileData = {
      status: false,
      projectName: "",
      entryUID: "",
      entryItem: "",
    };
    dispatch(setDeleteConfirmationStatus(tempProfileData));
  };

  const deleteFromDB = async () => {
    dispatch(
      setLoaderModalStatus({
        status: true,
        message: "Deleting " + projectName,
      }),
    );

    try {
      console.log("uid:", uid);
      console.log(item.projectName);

      let projectName = item.projectName;
      let updatedProjectList = projectList;

      //pass this to backend - filter out and update the list in backend and send respond.
      await axios
        .post(ADDR + "/deleteProjectFromProjectList", {
          uid,
          projectName,
          updatedProjectList,
        })
        .then((response) => {
          console.log(response);

          dispatch(
            setDeleteConfirmationStatus({
              status: false,
              message: "",
            }),
          );

          // Assuming `arrayName` is your current project list array from Redux state
          const updatedProjectList = projectList.filter(
            (project) => project.projectName !== projectName,
          );

          // Dispatch the updated list
          dispatch(setProjectList(updatedProjectList));

          dispatch(
            setLoaderModalStatus({
              status: false,
              message: "Deleting " + projectName,
            }),
          );
        });
    } catch (e) {
      dispatch(
        setErrorModalStatus({
          status: true,
          message: "Error Deleting",
        }),
      );

      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "Deleting " + projectName,
        }),
      );
    }
  };

  const deleteFromFirebase = async () => {
    dispatch(
      setLoaderModalStatus({
        status: true,
        message: "Deleting " + projectName,
      }),
    );

    try {
      await deleteProjectEntry(uid, item);
      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "",
        }),
      );
      window.location.reload();
    } catch (e) {
      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "",
        }),
      );
      dispatch(
        setErrorModalStatus({
          status: true,
          message: "Something Went Wrong",
        }),
      );
    }
  };

  // useEffect(() => {
  //   console.log(profileInfoModal);
  // }, [profileInfoModal]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-lightbgone dark:bg-darkbgtwo opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-instagram-dark backdrop-blur-xl opacity-100 p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl text-center font-bold leading-6 text-darkbgone dark:text-lightbgtwo py-2 mt-5"
                  >
                    Delete {projectName}?
                  </Dialog.Title>
                  <div className="mt-2 flex flex-col justify-center items-center">
                    <div className="mt-4 mb-4 flex gap-2">
                      <button
                        class="w-fit h-10 text-darkbgone dark:text-lightbgtwo text-instagram-dark bg-instagram-lightgrey  hover:bg-transparent  hover:text-instagram-light ring-2 ring-darkbgtwo dark:ring-lightbgtwo font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"
                        onClick={closeModal}
                      >
                        No
                      </button>

                      <button
                        class="w-fit h-10 text-instagram-light bg-red-700  hover:bg-transparent  hover:text-instagram-light ring-2 ring-darkbgtwo dark:ring-lightbgtwo font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"
                        onClick={deleteFromDB}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DeleteConfirmationModal;
