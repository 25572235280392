import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import {
  setAddUserToTeamModal,
  setCreateProjectModal,
  setErrorModalStatus,
  setLoaderModalStatus,
  setSearchModal,
} from "./ModalSlice";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import LoadingIcons from "react-loading-icons";
import { useAuthState } from "react-firebase-hooks/auth";
import { addUserProject, auth } from "../../app/firebase";
import { setCurrentSelectedProject } from "../Home/HomeSlice";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Add } from "../../assets/add.svg";
import { LoaderModal } from "./LoaderModal";
import axios from "axios";
import { ADDR } from "../../config/config";
import { ErrorModal } from "./ErrorModal";
import { ReactComponent as VectorSvg } from "../../assets/v2/Vector.svg";

const SearchCaptionModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchMessage, setSearchMessage] = useState("");

  let isOpen = useAppSelector((state) => state.modalSlice.searchModal.status);

  let newMediaData = useAppSelector(
    (state) => state.modalSlice.searchModal.data,
  );

  let newMediaDataBackup = useAppSelector(
    (state) => state.modalSlice.searchModalDataBackup.data,
  );

  let name = useAppSelector(
    (state) => state.modalSlice.addUserToTeamModal.name,
  );
  let role = useAppSelector(
    (state) => state.modalSlice.addUserToTeamModal.role,
  );

  let email = useAppSelector(
    (state) => state.modalSlice.addUserToTeamModal.email,
  );

  let createdDate = useAppSelector(
    (state) => state.modalSlice.addUserToTeamModal.createdDate,
  );

  let createProjectModalObject = useAppSelector(
    (state) => state.modalSlice.createProjectModal,
  );

  const [newUsername, setNewUsername] = useState(""); // State for input field
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(false);

  let [nameTemp, setName] = useState("");
  let [roleTemp, setRole] = useState("Coming Soon");
  let [emailTemp, setEmail] = useState("");
  let [allUsers, setAllUsers] = useState([]);

  const closeModal = () => {
    dispatch(
      setSearchModal({
        status: false,
        data: [],
      }),
    );
  };

  const goToPostOnInstagram = (link) => {
    console.log(link);
    window.open(link, "_blank");
  };

  const projectNameHandler = (e) => {
    let temp = { ...createProjectModalObject };
    temp.projectName = e.target.value;
    dispatch(setCreateProjectModal(temp));
  };

  const addUsernameHandler = () => {
    if (newUsername.trim() !== "") {
      let temp = { ...createProjectModalObject };
      temp.usernames = [...temp.usernames, newUsername];
      dispatch(setCreateProjectModal(temp));
      setNewUsername(""); // Clear the input after adding
    }
  };

  const nameHandler = (e) => {
    let value = e.target.value;

    setName(value);
  };

  const emailHandler = (e) => {
    let value = e.target.value;

    setEmail(value);
  };

  const addUserToTeam = async () => {
    if (nameTemp === "") {
      dispatch(
        setErrorModalStatus({
          status: true,
          message: "Please Enter Name",
        }),
      );
      return;
    }

    if (emailTemp === "") {
      dispatch(
        setErrorModalStatus({
          status: true,
          message: "Please Enter Email",
        }),
      );
      return;
    }

    dispatch(
      setLoaderModalStatus({
        status: true,
        message: "Adding User",
      }),
    );

    console.log("ADD TEAM");
    //Get license - based on license allow to add certain number of team members;
    closeModal();
    if (user) {
      let email = user.email;
      let userID = user.uid;

      let licenseDetail = await axios
        .post(ADDR + "/getLicenseDetailOfUser", { email, userID })
        .then((response) => {
          return response;
        });

      console.log(licenseDetail.data[0]);

      let licenseDetailsFromDB = licenseDetail.data[0];

      if (licenseDetailsFromDB.planType === "standard") {
        console.log("standard");
        //No Team Users
        dispatch(
          setErrorModalStatus({
            status: true,
            message: "Upgrade To Premium And Above",
          }),
        );
      } else if (licenseDetailsFromDB.planType === "premium") {
        console.log("premium");
        //2 users
        //Get count of users first then add or show error
        let email = user.email;
        let userID = user.uid;
        let result = await axios
          .post(ADDR + "/getCountOfUsersAddedByUser", { email, userID })
          .then((response) => {
            console.log(response);
            return response.data[0];
          });
        const { "COUNT(*)": count } = result;

        console.log(count);

        if (count < 2) {
          //Add

          let name = nameTemp;
          let role = roleTemp;
          email = emailTemp;
          let addedByEmail = user.email;
          let addedByUID = user.uid;
          await axios
            .post(ADDR + "/addUserToTeam", {
              name,
              email,
              role,
              addedByEmail,
              addedByUID,
            })
            .then((response) => {
              console.log(response);
            });
        } else {
          //Dont Add
          dispatch(
            setErrorModalStatus({
              status: true,
              message: "Max Users Added - Upgrade Plan.",
            }),
          );
        }
      } else if (licenseDetailsFromDB.planType === "enterprise") {
        console.log("enterprise");
        //5 users
        //Get count of users first then add or show error
        let email = user.email;
        let userID = user.uid;
        let result = await axios
          .post(ADDR + "/getCountOfUsersAddedByUser", { email, userID })
          .then((response) => {
            console.log(response);
            return response.data[0];
          });
        const { "COUNT(*)": count } = result;

        console.log(count);

        if (count < 5) {
          //Add

          let name = nameTemp;
          let role = roleTemp;
          email = emailTemp;
          let addedByEmail = user.email;
          let addedByUID = user.uid;
          await axios
            .post(ADDR + "/addUserToTeam", {
              name,
              email,
              role,
              addedByEmail,
              addedByUID,
            })
            .then((response) => {
              console.log(response);
            });
        } else {
          //Dont Add
          dispatch(
            setErrorModalStatus({
              status: true,
              message: "Max Users Added.",
            }),
          );
        }
      }
    }

    dispatch(
      setLoaderModalStatus({
        status: false,
        message: "Adding User",
      }),
    );

    //Reset inputs

    setName("");
    setEmail("");

    //Re-render list here

    // if(user){
    //   getUsersAddedByAdmin(user.email, user.uid);

    // }

    //Reloading page for now

    window.location.reload();
  };

  //   const createProjectFirebase = async () => {
  //     dispatch(
  //       setLoaderModalStatus({
  //         status: true,
  //         message: "Creating Project",
  //       }),
  //     );
  //     closeModal();
  //     let uid = user.uid;
  //     let projectNameTemp = projectName;
  //     let userList = [...new Set(usernameList)];
  //     let cDate = createdDate;
  //     let lModified = lastModified;
  //     let lUsed = lastUsed;
  //     let success = await addUserProject(
  //       uid,
  //       projectNameTemp,
  //       cDate,
  //       lModified,
  //       lUsed,
  //       userList,
  //     );
  //     if (success) {
  //       //Redirect to dashboard/Home
  //       //SET CURRENT PROJECT SOMEHOW

  //       dispatch(
  //         setCurrentSelectedProject({
  //           projectName: projectName,
  //           projectList: userList,
  //         }),
  //       );

  //       dispatch(
  //         setLoaderModalStatus({
  //           status: false,
  //           message: "Creating Project",
  //         }),
  //       );

  //       navigate("/home", {
  //         state: {
  //           projectName: projectName,
  //           projectList: userList,
  //         },
  //       });
  //     } else {
  //       //Display error
  //       console.log("E");
  //     }
  //   };

  const searchTermThroughPosts = (e) => {
    let term = e.target.value.toLowerCase();
    console.log(term);
    console.log(term.length);

    if (term.length === 0) {
      // dispatch(setMediaData([...mediaDataBackup]));

      dispatch(
        setSearchModal({
          status: true,
          data: [...newMediaDataBackup],
        }),
      );
      setSearchMessage("");
      return;
    }

    let dataTemp = [...newMediaData];
    dataTemp = dataTemp.filter((item) =>
      //Sometimes this caption.lowerCase dosent work - so add a condition to avoid error
      item.caption.toLowerCase().includes(term),
    );
    if (dataTemp.length > 0) {
      let filteredArray = [...dataTemp];
      dispatch(
        setSearchModal({
          status: true,
          data: [...filteredArray],
        }),
      );
      setSearchMessage(filteredArray.length + " results found");
    } else {
      setSearchMessage("No match found");

      dispatch(
        setSearchModal({
          status: true,
          data: [...newMediaDataBackup],
        }),
      );
    }
  };

  const getUsersAddedByAdmin = async (email, userID) => {
    let allUsers = await axios
      .post(ADDR + "/getUsersAddedByAdmin", { email, userID })
      .then((response) => {
        return response.data;
      });

    console.log(allUsers);
    setAllUsers(allUsers);
  };

  // const sortByLikes = () => {
  //   let dataTemp = [...mediaData];
  //   let sortedArray;

  //   if (isDescending) {
  //     sortedArray = dataTemp.sort((a, b) => b.like_count - a.like_count);
  //   } else {
  //     sortedArray = dataTemp.sort((a, b) => a.like_count - b.like_count);
  //   }

  //   handleSelection("likes");
  //   setLikeLabelBold("font-bold text-white");

  //   dispatch(setIsDescending(!isDescending));

  //   dispatch(setMediaData([...sortedArray]));
  // };

  function formatNumber(num) {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
    }
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
    }
    if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
    }
    return num.toString(); // Numbers below 1000
  }

  useEffect(() => {
    if (user) {
      //   getUsersA=ddedByAdmin(user.email, user.uid);
    }
  }, [user]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-lightbgone dark:bg-darkbgtwo opacity-50" />
          </Transition.Child>

          <div className="fixed  inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[1000px] h-[550px] border border-1 border-darkbgfour transform overflow-hidden rounded-2xl bg-instagram-dark backdrop-blur-xl opacity-100 p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between">
                    <div className="w-3/4">
                      <Dialog.Title
                        as="h3"
                        className="text-3xl text-left font-semibold text-darkbgone dark:text-lightbgone py-2"
                      >
                        Search Captions{" "}
                      </Dialog.Title>

                      <div className="flex w-full items-center gap-2">
                        <textarea
                          onChange={searchTermThroughPosts}
                          class="w-full max-w-sm ml-1 h-8 p-1 mb-5 outline outline-4 outline-instagram-lightgrey font-medium text-md rounded-lg bg-white shadow transition duration-300 resize-none"
                          placeholder="Enter search term"
                        ></textarea>

                        <p className="text-darkbgone dark:text-lightbgone  mx-4 h-12 font-semibold">
                          {searchMessage}
                        </p>
                      </div>
                    </div>

                    <button
                      onClick={closeModal}
                      class="cursor-pointer rounded-full bg-grey dark:bg-lightbgtwo w-10 h-10 my-5 mx-4 flex justify-center items-center "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="w-6 h-6 dark:text-black text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <>
                    <div className="overflow-x-auto h-[600px] ">
                      <table className="min-w-full table-auto ">
                        <thead className="w-full sticky top-0 bg-lightbgtwo dark:bg-darkbgthree z-10">
                          <tr className="text-grey dark:text-grey border-b border-grey">
                            <th
                              className={`select-none px-4 py-2 text-left cursor-not-allowed`}
                            >
                              Media
                            </th>
                            <th
                              // onClick={sortByLikes}

                              className={`select-none px-4 py-2 text-left  cursor-pointer`}
                            >
                              Like
                            </th>
                            <th
                              className={`select-none px-4 py-2 text-left  cursor-pointer`}
                            >
                              Comments
                            </th>
                            <th
                              className={`select-none px-4 py-2 text-left  cursor-pointer`}
                            >
                              Date
                            </th>
                            <th
                              className={`select-none px-4 py-2 text-left  cursor-pointer`}
                            >
                              Account
                            </th>

                            <th className=" cursor-not-allowed	 bg-lightbgtwo dark:bg-darkbgthree px-4 py-2 text-left">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="overflow-y-auto">
                          {loader === true && (
                            <tr
                              // onClick={() => goToPostOnInstagram(item.permalink)}
                              // key={item.id}
                              className="border-none  hover:cursor-pointer dark:hover:bg-lightbgone dark:hover:text-darkbgone text-darkbgone dark:text-lightbgone border-b border-grey"
                            >
                              <td colSpan="8" className="px-4 py-4 text-center">
                                <div className="w-full mt-32 h-24 my-4 text-center justify-center flex">
                                  <LoadingIcons.Puff
                                    stroke="#9E9EA4"
                                    fill="#9E9EA4"
                                    height="120px"
                                    width="120px"
                                  />
                                </div>
                                <br />

                                <p className="text-3xl text-center font-semibold text-darkbgone dark:text-lightbgone">
                                  {"Fetching"}
                                </p>
                                <br />
                              </td>
                            </tr>
                          )}

                          {loader === false &&
                          newMediaData !== undefined &&
                          newMediaData.length > 0 ? (
                            newMediaData.map((item) => (
                              <tr
                                // onClick={() => goToPostOnInstagram(item.permalink)}
                                key={item.id}
                                className="hover:cursor-pointer dark:hover:bg-lightbgone dark:hover:text-darkbgone hover:bg-darkbgone hover:text-lightbgone text-darkbgone dark:text-lightbgone border-b border-grey"
                              >
                                <td className={`px-4 py-2 text-left`}>
                                  {item.media_type === "CAROUSEL_ALBUM" ? (
                                    <p>Carousel Album</p>
                                  ) : item.media_type === "IMAGE" ? (
                                    <p>Image</p>
                                  ) : item.media_type === "VIDEO" ? (
                                    <p>Video</p>
                                  ) : (
                                    <p>Unknown media type</p>
                                  )}
                                </td>
                                <td className={`px-4 py-2 text-left`}>
                                  {formatNumber(item.like_count)}
                                  {item.like_count === undefined && (
                                    <p>Likes Hidden</p>
                                  )}
                                </td>

                                <td className={`px-4 py-2 text-left`}>
                                  {formatNumber(item.comments_count)}
                                </td>
                                <td className={`px-4 py-2 text-left`}>
                                  {new Date(item.timestamp).getFullYear() +
                                    "-" +
                                    (new Date(item.timestamp).getMonth() + 1) +
                                    "-" +
                                    new Date(item.timestamp).getDate()}
                                </td>

                                <td className={`px-4 py-2 text-left`}>
                                  {item.username}
                                </td>

                                <td className="px-4 py-2">
                                  <div className="flex justify-end space-x-2">
                                    <button
                                      className="hover:scale-110"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // deleteEntry(item);
                                        goToPostOnInstagram(item.permalink);
                                      }}
                                    >
                                      {/* <DeleteIcon className="w-6 h-6 rounded-full text-darkbgone dark:text-lightbgone" /> */}

                                      <VectorSvg className="w-4 h-8 rounded-full text-darkbgone dark:text-lightbgone" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <></>
                          )}

                          {loader === true &&
                          newMediaData !== undefined &&
                          newMediaData.length > 0 ? (
                            <tr className="hover:cursor-pointer dark:hover:bg-lightbgone dark:hover:text-darkbgone hover:bg-darkbgone hover:text-lightbgone text-darkbgone font-semibold dark:text-lightbgone border-b border-grey">
                              <td colSpan="8" className="px-4 py-4 text-center">
                                Select a profile from the list and click
                                'Update' to fetch data.{" "}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <LoaderModal />
      <ErrorModal />
    </>
  );
};

export default SearchCaptionModal;
