import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import {
  setCreateProjectModal,
  setErrorModalStatus,
  setLoaderModalStatus,
  setPaymentModal,
} from "./ModalSlice";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import LoadingIcons from "react-loading-icons";
import { useAuthState } from "react-firebase-hooks/auth";
import { addUserProject, auth, storePaymentSS } from "../../app/firebase";
import { setCurrentSelectedProject } from "../Home/HomeSlice";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Add } from "../../assets/add.svg";
import { LoaderModal } from "./LoaderModal";
import imageCompression from "browser-image-compression";
import axios from "axios";
import { ADDR } from "../../config/config";
import { ErrorModal } from "./ErrorModal";

const PaymentModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [user, loading] = useAuthState(auth);

  const [image, setImage] = useState(null);
  const [planType, setPlan] = useState("standard");
  const [price, setPlanPrice] = useState("24");

  let isOpen = useAppSelector((state) => state.modalSlice.paymentModal.status);
  let plan = useAppSelector((state) => state.modalSlice.paymentModal.plan);

  let paymentModal = useAppSelector((state) => state.modalSlice.paymentModal);

  // const createPayment = async () => {
  //   //Create a call to db - done

  //   console.log(image);
  //   console.log(planType);
  //   console.log(price);
  //   console.log(user.uid);
  //   let userID = user.uid;
  //   let userEmail = user.email;

  //   await axios.post(ADDR + "/storePaymentInfo", {
  //     planType,
  //     price,
  //     userID,
  //     userEmail,
  //   });

  //   //Store image in firebase db

  //   await storePaymentSS(user.uid,image);

  //   //Add loader and error handling

  //   dispatch(
  //     setPaymentModal({
  //       status: false,
  //       plan: plan,
  //     }),
  //   );

  //   window.location.reload();
  // };

  const createPayment = async () => {
    try {
      if (image === null) {
        dispatch(
          setErrorModalStatus({
            status: true,
            message: "Upload Payment Screenshot",
          }),
        );
        return;
      }

      dispatch(
        setLoaderModalStatus({
          status: true,
          message: "Storing Payment Info",
        }),
      );

      // Create a call to db - done
      // console.log(image);
      // console.log(planType);
      // console.log(price);
      // console.log(user.uid);

      let userID = user.uid;
      let userEmail = user.email;

      // Make API call to store payment information
      await axios.post(ADDR + "/storePaymentInfo", {
        planType,
        price,
        userID,
        userEmail,
      });

      // Store image in Firebase database
      await storePaymentSS(user.uid, image);

      // Update state and reload page
      dispatch(
        setPaymentModal({
          status: false,
          plan: plan,
        }),
      );

      window.location.reload();
    } catch (error) {
      console.error("Error occurred during payment creation:", error);

      // Optional: Add user feedback mechanism (e.g., toast or alert)
      dispatch(
        setErrorModalStatus({
          status: true,
          message: "Payment Error - Contact Support",
        }),
      );
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // setIsLoading(true);

        // Compress the image
        const options = {
          maxSizeMB: 1, // Compress to 1 MB max
          maxWidthOrHeight: 1920, // Resize max dimensions
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);

        // Convert compressed image to base64
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result); // Set the image as a data URL
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  const optionHandler = (e) => {
    let optionSelected = e.target.value;
    if (optionSelected === "standard") {
      setPlanPrice("24");
      setPlan("standard");
    } else if (optionSelected === "premium") {
      setPlanPrice("46");
      setPlan("premium");
    } else if (optionSelected === "enterprise") {
      setPlanPrice("79");
      setPlan("enterprise");
    }
  };

  const closeModal = () => {
    dispatch(
      setPaymentModal({
        status: false,
        plan: plan,
      }),
    );
  };

  //   useEffect(() => {
  //     dispatch(
  //       setUserDisplayImage({
  //         image: selectedImage,
  //       }),
  //     );
  //   }, [selectedImage, dispatch]);

  useEffect(() => {}, [image]);

  useEffect(() => {
    console.log(plan);
    if (plan === "standard") {
      setPlanPrice("24");
      setPlan("standard");
    } else if (plan === "premium") {
      setPlanPrice("46");
      setPlan("premium");
    } else if (plan === "enterprise") {
      setPlanPrice("79");
      setPlan("enterprise");
    }
  }, [plan]);

  useEffect(() => {
    if (user) {
      return;
    } else {
      // window.location.replace("/login");
    }
  }, [user, loading]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-lightbgone dark:bg-darkbgtwo opacity-50" />
          </Transition.Child>

          <div className="fixed  inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full  border border-1 border-darkbgfour max-w-md transform overflow-hidden rounded-2xl bg-instagram-dark backdrop-blur-xl opacity-100 p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl text-left font-semibold text-darkbgone dark:text-lightbgone py-2"
                    >
                      Payment
                    </Dialog.Title>

                    <button
                      onClick={closeModal}
                      class="cursor-pointer rounded-full bg-grey dark:bg-lightbgtwo w-10 h-10 my-2 mx-4 flex justify-center items-center "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="w-6 h-6 dark:text-black text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <label className="text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                    Select Plan
                  </label>
                  <select
                    value={plan}
                    onChange={optionHandler}
                    className="w-full rounded-[12px] text-left pl-4 py-2 my-2 font-medium border border-gray-300 dark:border-gray-600"
                  >
                    <option value="standard">Standard Plan</option>
                    <option value="premium">Premium Plan</option>
                    <option value="enterprise">Enterprise Plan</option>
                  </select>
                  <div className="flex justify-center">
                    <label className="text-darkbgone dark:text-lightbgtwo font-semibold justify-center">
                      You Pay
                    </label>
                  </div>
                  <div className="flex justify-center pt-2 pl-4">
                    <p className="text-darkbgtwo dark:text-lightbgtwo text-4xl">
                      {price}€
                    </p>

                    <p className="text-grey text-md">/month</p>
                  </div>

                  <div>
                    <p className="text-grey text-sm">
                      We’re working on implementing seamless payment solutions
                      for your convenience. Payments can be made directly to the
                      account below."
                    </p>

                    <table className="text-grey">
                      <tr>
                        <td>Bank Name</td>
                        <td>[Your Bank's Name]</td>
                      </tr>
                      <tr>
                        <td>Account Holder</td>
                        <td>[Your Company Name or Individual Name]</td>
                      </tr>
                      <tr>
                        <td>IBAN</td>
                        <td>[Your IBAN]</td>
                      </tr>
                      <tr>
                        <td>BIC/SWIFT Code</td>
                        <td>[Your Bank's BIC or SWIFT Code]</td>
                      </tr>
                      <tr>
                        <td>Reference/Details</td>
                        <td>[Payment Purpose or Invoice Number]</td>
                      </tr>
                    </table>

                    <p className="text-grey text-sm">
                      Please upload a payment screenshot for confirmation. Thank
                      you!
                    </p>
                  </div>

                  <div className="mt-2 flex flex-col justify-center">
                    {/* <input
                      id="imageUpload"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    /> */}

                    <input
                      id="imageUpload"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="block w-full text-sm text-darkbgone dark:text-lightbgone 
             file:mr-4 file:py-2 file:px-4
             file:rounded-full file:border-0
             file:text-sm file:font-semibold
             file:bg-lightbgthree dark:file:bg-darkbgthree
             file:text-darkbgone dark:file:text-lightbgone

             hover:file:bg-lightbgtwo dark:hover:file:bg-darkbgfour
             focus:outline-none"
                    />

                    <div className="mt-4">
                      <button
                        onClick={createPayment}
                        className="w-full cursor-pointer my-2 text-lightbgtwo bg-primary font-semibold rounded-[20px] text-lg px-5 py-4 text-center mr-3 md:mr-0 flex justify-center items-center"
                      >
                        Mark As Paid
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <LoaderModal />
      <ErrorModal />
    </>
  );
};

export default PaymentModal;
